<template>
  <v-layout>
    <v-app-bar color="white" dark fixed app>
      <Logo/>

      <v-spacer></v-spacer>

      <User/>
    </v-app-bar>

    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-layout>
</template>

<script>
import Logo from '@/components/AppBar/Logo'
import User from '@/components/AppBar/User'

export default {
  components: {
    Logo,
    User
  },

  beforeMount() {
    if (this.$store.getters.user.role !== 'company') {
      this.$router.push({path: `/${this.$store.getters.user.role}`})
    }
  }
}
</script>
